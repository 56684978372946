import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import "./article.css";
import { innerData } from "../components/constants/innerData";

const AllSpeaker = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">All Speakers</h2>
        </div>
      </div>
      <div
        className=" speakers distinguished-guest pb-4 pb-md-5 mt-5  pt-md-4"
        id="speakers"
      >
        <div className="speaker p-4 pb-md-4 pt-md-5">
          <h3 className="pb-md-3">Speakers</h3>
        </div>
        <div className="guest">
          {innerData.map((i) => (
            <div key={i} className="col-lg-2 col-md-6 col-sm-12">
              <div className="guest-card">
                <div className="card-img">
                  <img src={i.image} alt="...." className="w-100" />
                </div>
                <div className="card-title">
                  <img src={i.logo} height={50} alt="...." className="pt-1" />
                  <div className="card-name mt-2">
                    <h4>{i.name}</h4>
                  </div>
                  <div className="card-position">
                    <p>{i.position} </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllSpeaker;
