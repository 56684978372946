const innerData = [
  {
    id: "1",
    image: "./images/speaker/baikuntha.jpg",
    logo: "./images/sarkar.jpg",
    name: "Mr. Baikuntha Aryal",
    position: "Chief Secretary (GoN)",
  },
  {
    id: "2",
    image: "./images/keynote/mahaprasad.jpg",
    logo: "./images/keynote/nrb.jpg",
    name: " Mr. Maha Prasad dhikari",
    position: "Governer, NRB",
  },
  {
    id: "3",
    image: "./images/keynote/swarnimsir.jpg",
    logo: "./images/sarkar.jpg",
    name: "Hon. Swarnim Wagle, PHD",
    position: "Member Of Parliament",
  },
  {
    id: "4",
    image: "./images/speaker/sushil-bhatta.jpg",
    logo: "./images/speaker/ibn.png",
    name: "Mr. Sushil Bhatta",
    position: "CEO, Investment Board Nepal",
  },
  {
    id: "5",
    image: "./images/speaker/ramesh.jpg",
    logo: "./images/speaker/commission.jpg",
    name: "Dr. Ramesh Paudel",
    position: "Hon'ble Member, National Planning Commission",
  },
  {
    id: "6",
    image: "./images/speaker/rameshh.jpg",
    logo: "./images/speaker/sebon.png",
    name: " Mr. Ramesh Kumar Hamal",
    position: "Chairman, SEBON",
  },
  {
    id: "7",
    image: "./images/website/madhu.gif",
    logo: "./images/sarkar.jpg",
    name: "Mr. Madhu Kumar Marasini, Secretary",
    position: "Ministry Of Finance",
  },
  {
    id: "8",
    image: "./images/speaker/chandra.jpg",
    logo: "./images/speaker/fncci.jpg",
    name: "Mr. Chandra Dhakal ",
    position: "President, FNCCI",
  },
  {
    id: "18",
    image: "./images/speaker/rajendra.jpg",
    logo: "./images/speaker/chamber.png",
    name: "Mr. Rajendra Malla",
    position: "President, Nepal Chamber of Commerce",
  },
  {
    id: "9",
    image: "./images/keynote/pawan-golyan.jpg",
    logo: "",
    name: " Mr. Pawan Kumar Golyan",
    position: "Senior Entrepreneur",
  },
  {
    id: "10",
    image: "./images/speaker/upendra.jpeg",
    logo: "./images/keynote/cbfin.png",
    name: "MR. Upendra Poudyal",
    position: "Chairman,CBFIN",
  },
  {
    id: "11",
    image: "./images/website/sunil.jpg",
    logo: "./images/keynote/nba.jpg",
    name: "Mr. Sunil KC",
    position: "President, NBA",
  },
  {
    id: "12",
    image: "./images/website/hari.png",
    logo: "./images/website/cni.png",
    name: "Mr. Hari Bhakta  Sharma",
    position: "Former President, CNI",
  },
  {
    id: "13",
    image: "./images/speaker/bhawanirana.jpg",
    logo: "./images/speaker/fncci.jpg",
    name: "Mrs. Bhawani  Rana",
    position: "Former President, FNCCI",
  },
  {
    id: "14",
    image: "./images/speaker/bharat.jpg",
    logo: "./images/keynote/cbfin.png",
    name: "Mr. Bharat Raj Dhakal",
    position: "Exe Com Member CBFIN/Chairman-Muktinath Krishi Company",
  },
  {
    id: "15",
    image: "./images/speaker/dilip.jpg",
    logo: "./images/speaker/worldlink.jpg",
    name: "Mr. Dileep Agrawal",
    position: "Chairman, Worldlink Communications",
  },
  {
    id: "16",
    image: "./images/speaker/achal.jpg",
    logo: "./images/website/daraz.png",
    name: "Ms. Aanchal Kunwar",
    position: "Managing Director, Daraz Nepal",
  },
  {
    id: "17",
    image: "./images/speaker/akshay.jpg",
    logo: "./images/speaker/golyan.png",
    name: "Mr. Akshay Golyan",
    position: "Director, Golyan Group",
  },
];

export { innerData };
