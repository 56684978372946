import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllSpeaker from "./pages/AllSpeaker";
import Article from "./pages/Article";
import Register from "./pages/Register";
import Organizer from "./pages/Organizer";
import News from "./pages/News";

const App = lazy(() => import("./App"));
const Schedule = lazy(() => import("./pages/Schedule"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Business = lazy(() => import("./pages/Business"));

function Routess() {
  return (
    <>
      <Router>
        <Suspense fallback={<></>}>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about-the-nbs-2024" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/business-scenario-in-nepal" element={<Business />} />
            <Route path="/register" element={<Register />} />
            <Route path="/article-competition" element={<Article />} />
            <Route path="/all-speakers" element={<AllSpeaker />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/organizer" element={<Organizer />} />
            <Route path="/news" element={<News />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default Routess;
