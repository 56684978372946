import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";

const Headers = () => {
  return (
    <div className="sticky-top barss  pt-3 pt-lg-0 ">
      <Navbar collapseOnSelect expand="lg" className="navbars">
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
          }}
        >
          <Nav className="me-auto"></Nav>
          <Nav className="mt-2">
            <li className="nav_link">
              <span>Summit 2024</span>
              <ul className="navdropdown">
                <Link
                  to="/business-scenario-in-nepal"
                  className=" text-decoration-none"
                >
                  <li>Business Scenario in Nepal</li>
                </Link>
                <Link
                  to="/about-the-nbs-2024"
                  className=" text-decoration-none"
                >
                  <li>About the NBS 2024</li>
                </Link>
                <Link to="/schedule" className=" text-decoration-none">
                  <li>Schedule</li>
                </Link>
                <Link to="/organizer" className=" text-decoration-none">
                  <li>Organizer Team</li>
                </Link>
                <Link to="/previous-summit" className=" text-decoration-none">
                  <li>Previous Summit</li>
                </Link>
                <Link to="/contact-us" className=" text-decoration-none">
                  <li>Contact Us</li>
                </Link>
              </ul>
            </li>
            <li className="nav_link">
              <span>Pre-Event</span>
              <ul className="navdropdown">
                <Link
                  to="/investment-and-policy-dialogue"
                  className=" text-decoration-none"
                >
                  <li>Investment &amp; Policy Dialogue</li>
                </Link>
                <Link to="/koshi-province" className=" text-decoration-none">
                  <li>Koshi Province</li>
                </Link>
                <Link to="/madesh-province" className=" text-decoration-none">
                  <li>Madesh Province</li>
                </Link>
                <Link to="/gandaki-province" className=" text-decoration-none">
                  <li>Gandaki Province</li>
                </Link>
                <Link to="/lumbini-province" className=" text-decoration-none">
                  <li>Lumbini Province</li>
                </Link>
                <Link to="/karnali-province" className=" text-decoration-none">
                  <li>Karnali Province</li>
                </Link>
                <Link
                  to="/sudur-paschim-province"
                  className=" text-decoration-none"
                >
                  <li>Sudur Paschim Province</li>
                </Link>
              </ul>
            </li>
            <Link to="/schedule" className=" text-decoration-none text-dark">
              <li className="nav_link">
                <span>Agenda</span>
              </li>
            </Link>
            <li className="nav_link">
              <Link
                to="/all-speakers"
                className="text-dark text-decoration-none"
              >
                <span>Speakers</span>
              </Link>
            </li>

            <li className="nav_link">
              <span>Media Room</span>
              <ul className="navdropdown">
                <li>Press Release</li>
                <Link to="/news" className=" text-decoration-none">
                  <li>News Coverage</li>
                </Link>

                <li>Videos</li>
                <li>Gallery</li>
                <li>Reports</li>
              </ul>
            </li>
            <li className="nav_link">
              <span>FAQ</span>
            </li>
          </Nav>
          <Nav className="mt-2">
            <button className="head_register_button">Register</button>
          </Nav>
          <Nav className="mt-2">
            <button className="head_register_button" style={{ width: "130px" }}>
              Book a Stall
            </button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Headers;
