import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import "./article.css";

const Organizer = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Organizers</h2>
        </div>
      </div>
      <div
        className="moder distinguished-guest pt-3 pb-md-5 pt-md-4 pb-4"
        id="organizer"
      >
        <div className="guest pb-md-4">
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/bishalsir.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/organizer/nbis.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Bishal Gaire</h4>
                </div>
                <div className="card-position">
                  <p>
                    Executive Chairman, <br /> NBI{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/gopal.jpeg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/partners/organizer/cbfin.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Dr. Gopal Prasad Tiwari</h4>
                </div>
                <div className="card-position">
                  <p>
                    DG,
                    <br /> CBFIN{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/man.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/sarkar.jpg"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Man Bahadur Bk, PHD</h4>
                </div>
                <div className="card-position">
                  <p>
                    Former secretary,
                    <br /> GON
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/binod.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/organizer/nbis.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-2">
                  <h4>Mr. Binod Guragian</h4>
                </div>
                <div className="card-position">
                  <p>Consultant, Representative of NBI </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <div className="guest-card">
              <div className="card-img">
                <img
                  src="./images/organizer/naresh.jpg"
                  alt="...."
                  className="w-100"
                />
              </div>
              <div className="card-title">
                <img
                  src="./images/organizer/bf.png"
                  height={50}
                  alt="...."
                  className="pt-1"
                />
                <div className="card-name mt-1">
                  <h4>Mr. Naresh Prasad Shrestha</h4>
                </div>
                <div className="card-position">
                  <p>Chairman, Bishal Foundation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Organizer;
